.Container {
    position: relative;
}
.UploadContainer {
   position: relative;
   width: 100%;
   padding-top: 56.25%;
   
}

.UploadContent {
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    display: flex;
    border-width: 2px;
    border-style: dashed;
    border-radius: 0.2em;
    border-color: lightgray;
    cursor: pointer;
}

.UploadContent:hover{
    color: white !important;
    transition: 0.2s;
    background-color: gray;
}

.MediaItem {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    border: thin solid white;
    border-radius: 16px;
    box-shadow: 2px 2px 4px 0 lightgray;
    box-sizing: border-box;
    background-color: gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.2;
}

.MediaItem:hover {
    box-shadow: 4px 4px 4px 2px gray;
    transition: 0.2s;
}

.MediaItemContent {
    padding: 0.5em;
    color: white;
}

.Aspect16x9 {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    border: thin solid white;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.6s;
    
}

.Overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    transition: 0.2s;
    cursor: pointer;
    opacity: 0;
    color: ghostwhite;
    background-color: black;
    border: 3px solid white;
}

.Container:hover .Aspect16x9 {
    box-shadow: 8px 8px 6px 0 lightgray;
}

.Container:hover .Overlay {
    opacity: 0.4;
}