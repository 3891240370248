.Container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    transition: 0.2s;
}

.Container:hover {
    box-shadow: 0 2px 4px 2px lightgray;
    align-items: center;
}

.Icon {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    font-size: 18pt;
}

.Path {
    flex: 1 0 unset;
    padding-left: 8px;
}

.Edit {
    margin-left: auto;
    margin-right: 8px;
    transition: 0.2s;
    opacity: 0;
}

.Container:hover .Edit {
    opacity: 1;
}

.Instagram {
    font-size: 24pt;
    background-image: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.Facebook {
    background-color: #3b5998;
}

.YouTube {
    background-color: #ff0000;
}

.Twitter {
    background-color: #1da1f2;
}

.Linkedin {
    background-color: #0077b5;
}

.Github {
    background-color: #333;
    font-size: 24pt;
}

.Website {
    font-size: 24pt;
    background-color: lightgray;
}

