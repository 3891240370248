.Member {
    display: block;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    box-shadow: 2px 2px 4px 2px lightgray;
    border-radius: 16px;
    transition: 0.2s;
}

.Member:hover {
    box-shadow: 4px 4px 8px 4px lightgray;
    transition: 0.2s;
}

.Avatar {
    box-shadow: 2px 2px 2px 0 lightgray;
    box-sizing: border-box;
    border: thin solid white;
    width: 100% !important;
    padding-top: 100% !important;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    background-color: lightgray;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
