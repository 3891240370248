.Banner {
    position: relative;
    overflow: hidden !important;
    background-color: black;
}

.Background {
    z-index: 1;
    position: absolute;
    top: -16px !important;
    right: -16px !important;
    bottom: -16px !important;
    left: -16px !important;
    background-color: gray;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    filter: blur(8px);
    opacity: 0.5;
}

.Banner p {
    font-size: 14pt;
}

.Content {
    z-index: 2;
}
