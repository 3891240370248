.ReactionButtonText {
    transition: 0.2s;
    cursor: pointer;
    padding: 2px;
}

.ReactionButtonText:hover {  
    color : ghostwhite !important;  
    background-color: #231f20;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.ReplyButtonText:hover {    
    background-color: #231f20;
}

.LikeButtonText:hover {    
    background-color: #2d86ff;
}

.ReactionButton {
    transition: 0.2s;
    cursor: pointer;
}

.ReactionButton:hover {    
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
}

.ReactionButton:hover .LikeIcon {
    color: #2d86ff !important
}

.ReactionButton:hover .LaughIcon {    
    color:#fab005 !important;
}

.ReactionButton:hover .HeartIcon {    
    color: #af0011 !important;
}

