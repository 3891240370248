.UserListItem {
    transition: 0.2s;
}

.UserListItem:hover {
    box-shadow: 0 2px 4px 2px lightgray;
}

.UserListImage {
    min-width: 64px;
    padding-top: 100%;
    box-shadow: 2px 2px 4px 0 lightgray;
    background-color: lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: thin solid white;
    border-radius: 50%;
    transition: 0.2s;
}

.UserListItem:hover .UserListImage {
    box-shadow: 0 0 2px 2px lightgray;
}
