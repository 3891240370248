.CoverImage {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    border-radius: 8px;
    background-color: lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
