.Avatar {
    padding-top: 100%;
    box-shadow: 2px 2px 4px 0 lightgray;
    background-color: lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: thin solid white;
    border-radius: 50%;
    transition: 0.2s;
}

.userNameStyle {
    display: inline;
    color: #686868;
    font-weight: bold;
    font-size: 20px;
    font-style: italic;
  }
  .replyText {
    display: inline;
    color: #686868;
    font-weight: bold;
  }
  .inline {
    color: #686868;
    display: inline;
  }

  .datesOfComments {
    color: #00aff2;
    font-size: 12px;
    font-style: italic;
    display: inline;
  }
  .noComment {
    color: #686868;
    font-size: 20px;
    font-style: italic;
}