.MediaItem {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    border: thin solid white;
    border-radius: 16px;
    box-shadow: 2px 2px 4px 0 lightgray;
    box-sizing: border-box;
    background-color: gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.2s;
}

.MediaItem:hover {
    box-shadow: 4px 4px 4px 2px lightgray;
}

.MediaItemContent {
    padding: 0.5em;
    color: white;
}
