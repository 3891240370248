.Container {
    position: relative;
}

.Avatar {
    border: 3px solid white;
    padding-top: 100%;
    box-shadow: 4px 4px 4px 1px lightgray;
    background-color: lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 50%;
    transition: 0.2s;
    box-sizing: border-box;
}

.ModalAvatar {
    width: 25%;
    min-height: 1px;
    padding-top: 25%;
    border: 3px solid white;
    border-radius: 50%;
    box-shadow: 4px 4px 4px 0 lightgray;
    background-color: lightgray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.2s;
}

.Overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.2s;
    cursor: pointer;
    opacity: 0;
    color: ghostwhite;
    background-color: black;
    border: 3px solid white;
}

.Container:hover .Avatar {
    box-shadow: 8px 8px 6px 0 lightgray;
    filter: blur(8px);
}

.Container:hover .Overlay {
    opacity: 0.25;
}
