.SideNav {
    border-top-width: 1px;
    border-top-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0)) 1 0%;
}

.SideNavItem {
    /* TODO */
}

.SideNavItem:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.SideNavIcon {
    font-size: 14pt;
}

.SideNavText {
    /* TODO */
}
