.Row {
    cursor: pointer;
}

.Col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Content {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 0.5em;
}

.Sort {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 0.2s;
    padding: 0 1em;
}

.Sort:hover {
    box-shadow: 2px 2px 4px 0 lightgray;
}
