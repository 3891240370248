.Aspect16x9 {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: lightgray;
}

.CardImg {
    box-shadow: 4px 4px 4px 0 lightgray;
    border-radius: 16px !important;
    border: thin solid white;
    cursor: pointer;
    transition: 0.2s;
}

.CardImg:hover {
    box-shadow: 8px 8px 6px 0 lightgray;
    border-radius: 16px !important;
    opacity: 0.75;
}

.inline {
    color: #686868;
    display: inline;
}

.unvisibleComment {
    color: #686868;
    font-size: 20px;
    font-style: italic;
}
